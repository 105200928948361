import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(signed-in)": [~20,[2]],
		"/(signed-in)/accept-invite": [21],
		"/(public)/acceptable-use": [14],
		"/(signed-in)/admin": [22,[2,3]],
		"/(signed-in)/admin/organisations": [23,[2,3]],
		"/(signed-in)/admin/organisations/[id]": [24,[2,3]],
		"/(signed-in)/admin/organisations/[id]/edit": [25,[2,3]],
		"/(signed-in)/admin/plans": [26,[2,3]],
		"/(signed-in)/admin/plans/new": [28,[2,3]],
		"/(signed-in)/admin/plans/[id]": [27,[2,3]],
		"/(signed-in)/admin/users": [29,[2,3]],
		"/(signed-in)/admin/users/new": [30,[2,3]],
		"/(signed-in)/analytics": [31,[2]],
		"/(signed-in)/conversations": [32,[2]],
		"/(signed-in)/conversations/new": [41,[2]],
		"/(signed-in)/conversations/[id]": [33,[2,4]],
		"/(signed-in)/conversations/[id]/history": [34,[2,4]],
		"/(signed-in)/conversations/[id]/lexicon": [35,[2,4]],
		"/(signed-in)/conversations/[id]/questions": [36,[2,4]],
		"/(signed-in)/conversations/[id]/questions/library": [37,[2,4,5]],
		"/(signed-in)/conversations/[id]/settings": [38,[2,4]],
		"/(signed-in)/conversations/[id]/sharing": [39,[2,4]],
		"/(signed-in)/conversations/[id]/training": [40,[2,4]],
		"/(public)/cookies": [15],
		"/(public)/error": [16],
		"/(signed-in)/expired": [42,[2]],
		"/(signed-out)/forgotten-password": [70,[13]],
		"/(signed-in)/library": [43,[2]],
		"/(signed-in)/onboarding": [44,[6]],
		"/(signed-in)/onboarding/create": [48,[6]],
		"/(signed-in)/onboarding/[id]": [45,[6,7]],
		"/(signed-in)/onboarding/[id]/essentials": [46,[6,7]],
		"/(signed-in)/onboarding/[id]/questions": [47,[6,7]],
		"/(public)/preview/[experience_id]": [~17],
		"/(public)/privacy": [18],
		"/(signed-in)/questions/[id]": [49,[2,8]],
		"/(signed-in)/questions/[id]/library": [50,[2,8,9]],
		"/(signed-in)/questions/[id]/links": [51,[2,8]],
		"/(signed-in)/questions/[id]/responses": [52,[2,8]],
		"/(signed-in)/questions/[id]/suggestions": [53,[2,8]],
		"/(signed-in)/questions/[id]/training": [54,[2,8]],
		"/(signed-in)/record/[id]": [55],
		"/(signed-out)/reset-password": [71,[13]],
		"/(signed-in)/search": [56,[2]],
		"/(signed-in)/settings": [57,[2,10]],
		"/(signed-in)/settings/account": [58,[2,10]],
		"/(signed-in)/settings/account/billing/update": [59,[2,11]],
		"/(signed-in)/settings/account/billing/update/checkout": [61,[2,11]],
		"/(signed-in)/settings/account/billing/update/checkout/success": [62,[2,11]],
		"/(signed-in)/settings/account/billing/update/[plan]": [60,[2,11]],
		"/(signed-in)/settings/account/domains": [63,[2,10]],
		"/(signed-in)/settings/account/subscription": [64,[2,10]],
		"/(signed-in)/settings/team": [65,[2,10]],
		"/(signed-in)/settings/user": [66,[2,10]],
		"/(signed-out)/sign-in": [72,[13]],
		"/(signed-out)/sign-up": [73,[13]],
		"/(signed-out)/sign-up/confirm": [74,[13]],
		"/(signed-in)/team": [67,[2]],
		"/(signed-in)/team/invite": [68,[2,12]],
		"/(public)/terms": [19],
		"/(signed-in)/videos/[id]": [69,[2]],
		"/(signed-out)/waitlist": [75,[13]],
		"/(signed-out)/waitlist/confirm": [76,[13]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';